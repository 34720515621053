<template>
    <modal ref="modalAñadirSeleccion" titulo="Añadir seleccion de clientes específicos" tamano="modal-lg" :cargado-modal="loading" no-aceptar adicional="Añadir" @adicional="agregarClientes">
        <div class="row mx-0">
            <div class="col">
                <div class="row mx-0">
                    <div class="col-auto pt-2 text-general f-14">
                        Vigencia
                    </div>
                    <div class="col">
                        <div class="row mx-0">
                            <div class="col-1 d-middle-center px-0">
                                Inicio
                            </div>
                            <div class="col">
                                <el-date-picker
                                v-model="model.fecha_inicio"
                                type="datetime"
                                class="w-100"
                                placeholder="-"
                                format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-dd HH:mm"
                                :clearable="false"
                                />
                            </div>
                        </div>
                        <div class="row mx-0 my-3">
                            <div class="col-1 d-middle-center px-0">
                                Fin
                            </div>
                            <div class="col">
                                <el-date-picker
                                v-model="model.fecha_fin"
                                type="datetime"
                                class="w-100"
                                placeholder="-"
                                format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-dd HH:mm"
                                :clearable="false"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row mx-0 justify-center">
                    <div class="col">
                        <p class="text-center text-general f-14">
                            Límite de uso por cliente
                        </p>
                        <el-input-number v-model="model.cantidad_permitida" class="w-100" controls-position="right" :min="1" />
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Cupones from '~/services/cupones/cupones'
export default {
    data(){
        return{
            value1: '',
            limite: '',
            checked: false,
            model:{
                cantidad_permitida:1,
                fecha_inicio:null,
                fecha_fin:null
            },
            clientes:[],
            loading:false
        }
    },
    computed:{
        id_cupon(){
            return Number(this.$route.params.id_cupon);
        },
    },
    methods: {
        toggle(array_clientes){
            if(array_clientes.length === 0) return
            this.clientes = array_clientes
            this.$refs.modalAñadirSeleccion.toggle();
        },
        async agregarClientes(){
            if(this.model.fecha_inicio === null) return
            if(this.model.fecha_fin === null) return

            if(this.model.fecha_inicio > this.model.fecha_fin){
                this.notificacion('Advertencia','La fecha inicio No puede ser mayor a la fecha fin.','warning')
                return
            }
            this.loading = true
            try {
                this.model.id_cupon = this.id_cupon
                this.model.clientes = this.clientes.map(e => e.id_user)
                const {data} = await Cupones.agregarClientes(this.id_cupon, this.model)
                this.notificacion('Exito',`Se han agregado ${this.clientes.length} clientes al cupon`,'success')
                await this.$emit('update')
                this.$refs.modalAñadirSeleccion.toggle();


            } catch (e){
                this.error_catch(e)
            } finally{
                this.loading = false
            }


        }
    }
}
</script>
